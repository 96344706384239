"use strict";

var modalName;

function initSendContactForm() {

    $('#contact-form-celeritech').on('submit', function (event) {
        event.preventDefault();
    });

    jQuery.validator.addMethod("string", function (value, element) {
        return this.optional(element) || /^[a-zA-Z0-9ÑññáéíóúÁÉÍÓÚüÜ\. ]+$/.test(value);
    }, "Caracteres inválidos");

    $("#contact-form-celeritech").validate({
        rules: {
            fullname: {
                required: true,
                maxlength: 100,
                string: true
            },
            email: {
                required: true,
                email: true
            },
            company:{
                required: true,
                maxlength: 100,
                string: true
            },
            jobtitle: {
                required: true,
                maxlength: 100,
                string: true
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 10,
                digits: true
            },
            message: {
                required: true,
                maxlength: 100,
                string: true
            }
        },
        messages: {
            fullname: {
                required: "El nombre completo es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            },
            email: {
                required: "El correo es requerido",
                email: "Correo inválido"
            },
            company: {
                required: "El nombre de la empresa es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            },
            jobtitle: {
                required: "El puesto es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            },
            phone: {
                required: "El teléfono es requerido",
                minlength: jQuery.validator.format("Escribe {0} dígitos"),
                maxlength: jQuery.validator.format("Escribe máximo {0} dígitos"),
                digits: "Escribe solo dígitos"
            },
            message: {
                required: "El mensaje es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            }
        },
        submitHandler: function (form) {
            $.ajax({
                type: "POST",
                url: "/contacto.php",
                data: $(form).serialize(),
                dataType: 'html'
            })
                .done(function (data) {
                    showModalWindow(modalName = 'success-modal', '../assets/img/graphics/icons/modals/success.svg', 'success-icon');
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'form_submit',
                        'dl_form_id': form.id,
                        'dl_form_name': form.name,
                        'dl_form_destination': '/contacto.php',
                        'dl_form_submit_text': 'Enviar'
                    });
                })
                .fail(function (error) {
                    showModalWindow(modalName = 'error-modal', '../assets/img/graphics/icons/modals/error.svg', 'error-icon');
                })
        }
    });
}

function initSendGuideForm() {

    $('#download-guide').on('submit', function (event) {
        event.preventDefault();
    });

    jQuery.validator.addMethod("string", function (value, element) {
        return this.optional(element) || /^[a-zA-Z0-9ÑññáéíóúÁÉÍÓÚüÜ\. ]+$/.test(value);
    }, "Caracteres inválidos");

    $("#download-guide").validate({
        rules: {
            fullname: {
                required: true,
                maxlength: 100,
                string: true
            },
            email: {
                required: true,
                email: true
            },
            company:{
                required: true,
                maxlength: 100,
                string: true
            },
            jobtitle: {
                required: true,
                maxlength: 100,
                string: true
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 10,
                digits: true
            },
        },
        messages: {
            fullname: {
                required: "El nombre completo es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            },
            email: {
                required: "El correo es requerido",
                email: "Correo inválido"
            },
            company: {
                required: "El nombre de la empresa es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            },
            jobtitle: {
                required: "El puesto es requerido",
                maxlength: jQuery.validator.format("Escribe máximo {0} caracteres")
            },
            phone: {
                required: "El teléfono es requerido",
                minlength: jQuery.validator.format("Escribe {0} dígitos"),
                maxlength: jQuery.validator.format("Escribe máximo {0} dígitos"),
                digits: "Escribe solo dígitos"
            },
        },
        submitHandler: function (form) {
            $.ajax({
                type: "POST",
                url: "/download-guide.php",
                data: $(form).serialize(),
                dataType: 'html'
            })
                .done(function (data) {
                    showModalWindow(modalName = 'success-modal-guide', '../assets/img/graphics/icons/modals/success.svg', 'success-icon');
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'form_submit',
                        'dl_form_id': form.id,
                        'dl_form_name': form.name,
                        'dl_form_destination': '/download-guide.php',
                        'dl_form_submit_text': 'Descarga la guía gratuita'
                    });
                })
                .fail(function (error) {
                    showModalWindow(modalName = 'error-modal', '../assets/img/graphics/icons/modals/error.svg', 'error-icon');
                })
        }
    });
}

function showModalWindow(modalName, fileSvg, icon) {
    setTimeout(function () {
        new Vivus(icon, {
            type: "oneByOne",
            duration: 60,
            animTimingFunction: Vivus.EASE_OUT_BOUNCE,
            selfDestroy: true,
            file: fileSvg,
        });
    }, 300);

    $("#" + modalName).toggleClass("is-active");
    $("#" + modalName + " .modal-background").toggleClass("scaleInCircle");
    $("#" + modalName + " .modal-content").toggleClass("scaleIn");
    $("#" + modalName + " .modal-close").toggleClass("is-hidden");
    $("#scrollnav, #backtotop").toggleClass("is-hidden");
    setTimeout(function () {
        if ($(".dashboard-wrapper").length) {
            $("body").addClass("is-fixed");
        }
    }, 700);

    var resetIcon = $("#" + icon + " svg");

    $(".modal-close, .modal-dismiss").on("click", function () {
        $("#" + icon + " svg").replaceWith(resetIcon);
    });
}

$(".modal-close, .modal-dismiss").on("click", function () {
    $("#" + modalName + " .modal-background").toggleClass("scaleInCircle");
    $("#" + modalName + " .modal-content").toggleClass("scaleIn");
    $("#" + modalName + " .modal-close").toggleClass("is-hidden");
    //Restore native body scroll
    if ($(".dashboard-wrapper").length) {
        $("body").removeClass("is-fixed");
    }
    setTimeout(function () {
        $(".modal.is-active").removeClass("is-active");
        //Restore sticky nav and backktotop
        $("#scrollnav, #backtotop").toggleClass("is-hidden");
    }, 500);
});